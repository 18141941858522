<template>
  <div>

    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="UsersIcon"
          :statistic="Number(data.number_doctors).toLocaleString()"
          statistic-title="Bác sĩ"
          color="primary"
        />
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="UserCheckIcon"
          :statistic="Number(data.number_patients).toLocaleString()"
          statistic-title="Bệnh nhân"
          color="primary"
        />
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="HomeIcon"
          :statistic="Number(data.number_facilities).toLocaleString()"
          statistic-title="Cơ sở khám"
          color="primary"
        />
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="LifeBuoyIcon"
          :statistic="Number(data.number_meetings).toLocaleString()"
          statistic-title="Phiên khám"
          color="primary"
        />
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="FileTextIcon"
          :statistic="Number(data.number_prescriptions).toLocaleString()"
          statistic-title="Đơn thuốc"
          color="primary"
        />
      </b-col>
      <b-col
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          icon="CreditCardIcon"
          :statistic="Number(data.number_transaction_value).toLocaleString()"
          statistic-title="Giá trị giao dịch"
          color="primary"
        />
      </b-col>

    </b-row>

  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';

// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt';
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue';

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  setup() {

  },
  data() {
    return {
      data: {},
    };
  },
  created() {
    useJwt.getDashboard().then(response => {
      this.data = response.data.data;
    });
  },
};
</script>
